<template>
    <main class="main-page" id="">
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div :class="{ 'card ': !isSubPage }" class="">
                            <div class="grid gap-3 align-items-center justify-content-between">
                                <div class="col text-500 text-sm">
                                    Dapatkan Notifikasi Terbaru
                                </div>
                                <div class="col-fixed text-right">
                                    <a href="https://t.me/vnponsel" class="p-button p-button-primary" icon="pi pi-bell">Join Grup</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="">
                            <div class="">
                                <ServiceDashboardPage ref="serviceListPage"  :limit="10" :show-header="false" :show-breadcrumbs="true" :show-footer="false" :paginate="false" page-store-key="INDEX-SERVICE-DASHBOARD" is-sub-page>
                                </ServiceDashboardPage>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="">
                            <div class="">
                                <ProdukDashboardPage ref="produkListPage"  :limit="10" :show-header="true" :show-breadcrumbs="true" :show-footer="false" :paginate="false" page-store-key="INDEX-PRODUK-DASHBOARD" is-sub-page>
                                </ProdukDashboardPage>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>

</template>
<script setup>
	import {  ref } from 'vue';
	
	
	import { useApp } from 'src/composables/app.js';
	
	
	import ServiceDashboardPage from "../service/dashboard.vue";
import ProdukDashboardPage from "../produk/dashboard.vue";
	
	const props = defineProps({
		pageName: {
			type: String,
			default: 'home',
		},
		routeName: {
			type: String,
			default: 'home',
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	
	const pageReady = ref(true);
	
</script>
<style scoped>
</style>
